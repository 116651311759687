import * as React from 'react'
import { styled, SxProps, Theme } from '@mui/material/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import CheckIcon from '@mui/icons-material/Check'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import InputBase from '@mui/material/InputBase'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popper, { PopperProps } from '@mui/material/Popper'
import { useTranslation } from 'react-i18next'

const OuterPopper = styled(Popper)(({ theme }) => ({
  backgroundColor: '#fff',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 5,
  zIndex: theme.zIndex.modal,
}))

const InnerPopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    margin: 0,
    color: 'inherit',
    boxShadow: 'none',
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    maxHeight: '30vh',
    padding: 0,
    backgroundColor: '#fff',
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      padding: theme.spacing(1, 2),
      alignItems: 'center',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props: PopperProps) {
  const { anchorEl, disablePortal, open, ...rest } = props

  return <InnerPopper {...(rest as any)} />
}

const SearchInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '& input': {
    padding: 8,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 5,
  },
}))

type Farm = {
  id: string
  displayName: string
  timezone: string
  location: string
}

export type FarmPickerProps = {
  onClick: (option: unknown) => void
  options: Farm[]
  sx?: SxProps<Theme>
  value: Farm
}

function FarmPicker(props: FarmPickerProps) {
  const { onClick, options = [], sx, value} = props

  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<Element>()

  const open = Boolean(anchorEl)
  const enabled = options.length > 0

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (enabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  return (
    <>
      <List sx={sx}>
        <ListItemButton
          aria-controls='farm-picker'
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='listbox'
          aria-label={t('header.farmPicker.changeFarm')!}
          disableRipple={!enabled}
          disableTouchRipple={!enabled}
          id='farm-picker-button'
          sx={{
            py: '4px',
            px: '4px',
            ...(!enabled && {
              '&:hover': {
                background: 'none',
              },
            }),
          }}
          onClick={handleOpen}
        >
          <ListItemText
            primary={value?.displayName}
            primaryTypographyProps={{
              variant: 'body1',
              // sx: {
              //   overflow: 'hidden',
              //   textOverflow: 'ellipsis',
              // },
            }}
            sx={{
              whiteSpace: 'nowrap',
              my: 0,
            }}
          />
          {enabled && (
            <ListItemIcon sx={{ minWidth: 0 }}>
              <ArrowDropDownIcon />
            </ListItemIcon>
          )}
        </ListItemButton>
      </List>
      <OuterPopper
        anchorEl={anchorEl}
        id={open ? 'farm-picker' : undefined}
        open={open}
        placement='bottom-end'
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              getOptionLabel={(option) => option.displayName}
              noOptionsText={t('No farms')}
              open
              options={options.slice().sort((a, b) => {
                return a.id.localeCompare(b.id)
              })}
              PopperComponent={PopperComponent}
              renderInput={({ inputProps, InputProps }) => (
                <SearchInput
                  autoFocus
                  inputProps={inputProps}
                  placeholder={t('Select farm')!}
                  ref={InputProps.ref}
                  sx={{ width: 300 }}
                />
              )}
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  key={`${option.id}`}
                  component='li'
                  selected={
                    value.id === option.id
                  }
                  onClick={(event: React.MouseEvent<HTMLLIElement>) => {
                    if (props.onClick) {
                      props.onClick(event)
                    }
                    onClick(option)
                    handleClose()
                  }}
                >
                  <ListItemText
                    primary={option.displayName}
                  />
                  {option.id === value.id && (
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 16, minWidth: '16px' }} />
                    </ListItemIcon>
                  )}
                </MenuItem>
              )}
            />
          </div>
        </ClickAwayListener>
      </OuterPopper>
    </>
  )
}

export default FarmPicker