import type {} from '@mui/material/themeCssVarsAugmentation'
import { experimental_extendTheme as extendTheme } from '@mui/material/styles'
import { getDesignTokens } from './getDesignTokens'
import { getThemedComponents } from './getThemedComponents'
import { omit } from 'lodash'

const light = getDesignTokens('light')
const dark = getDesignTokens('dark')

const theme = extendTheme({
  cssVarPrefix: 'flockfocus',
  colorSchemes: {
    light: {
      palette: light.palette,
    },
    dark: {
      palette: dark.palette,
    },
  },
  // May need to customise typography...
  ...omit(light, 'palette'),
  ...getThemedComponents(),
})

export default theme
