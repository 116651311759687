import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { router } from '~/modules'
import { RouterProvider } from 'react-router-dom'
import { useAuthRequired } from './hooks'

const App = function App() {
  const isAuthenticated = useAuthRequired()

  const loading = (
    <Backdrop invisible open>
      <CircularProgress color='primary' />
    </Backdrop>
  )

  return isAuthenticated ? <RouterProvider router={router} /> : loading
}

export default App

