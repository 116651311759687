import { Compose } from '~/components'
import AuthContext from './AuthContext'
import SWRContext from './SWRContext'
import ThemeContext from './ThemeContext'
import FarmProvider from './FarmContext'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import enGb from 'date-fns/locale/en-GB';

export interface AppContextProps {
  children: React.ReactNode
}

function AppContext({ children }: AppContextProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGb}>
      <Compose
        providers={[AuthContext, SWRContext, ThemeContext, FarmProvider]}
      >
        {children}
      </Compose>
    </LocalizationProvider>
  )
}

export default AppContext
