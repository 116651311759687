import { Checkbox, Container, Paper, Toolbar, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RouteObject } from 'react-router-dom';
import useSWR from 'swr';
import { useFarm } from '~/hooks/useFarm';

export const settingsRoute: RouteObject = {
  path: '/cameras',
  element: <Cameras />,
}

function Cameras() {
  const { farm } = useFarm()
  const { data: cameras } = useSWR(`/farms/${farm?.id}/cameras`)
  
  return (
    <Container sx={{ maxWidth: "lg", marginTop: 5 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Toolbar 
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Cameras
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Purpose</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cameras?.map((row: any) => (
                <TableRow
                  key={row.deviceId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {row.deviceId}
                  </TableCell>
                  <TableCell>{row.deviceName}</TableCell>
                  <TableCell>
                    <Checkbox disabled checked={row.enabled} />
                  </TableCell>
                  <TableCell>{row.purpose}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}
