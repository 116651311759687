import { Auth0Provider } from '@auth0/auth0-react'

const AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE || ''
const CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID || ''
const DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN || ''

export interface AuthContextProps {
  children?: React.ReactNode
}

function AuthContext({ children }: AuthContextProps) {  
  return (
    <Auth0Provider
      authorizationParams={{
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
      }}
      clientId={CLIENT_ID}
      domain={DOMAIN}
      // useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthContext
