import * as React from 'react'
import {
  Auth0ContextInterface,
  RedirectLoginOptions,
  useAuth0,
  User,
} from '@auth0/auth0-react'

export interface AuthRequiredHookOptions {
  /**
   * Check the user object for JWT claims and return a boolean indicating
   * whether or not they are authorized to view the component.
   */
  claimCheck?: (claims?: User) => boolean
  /**
   * The context to be used when calling useAuth0, this should only be provided if you are using multiple Auth0Providers
   * within your application and you wish to tie a specific component to a Auth0Provider other than the Auth0Provider
   * associated with the default Auth0Context.
   */
  context?: React.Context<Auth0ContextInterface>
  /**
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: RedirectLoginOptions
  /**
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element
  /**
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string)
}

export function useAuthRequired(options: AuthRequiredHookOptions = {}) {
  const {
    claimCheck = (): boolean => true,
    context,
    loginOptions,
    returnTo,
  } = options

  const { error, isAuthenticated, isLoading, loginWithRedirect, user } =
    useAuth0(context)

  const routeIsAuthenticated = isAuthenticated && claimCheck(user)

  React.useEffect(() => {
    if (isLoading || routeIsAuthenticated || error) {
      return
    }

    const options = {
      ...loginOptions,
      appState: {
        ...(loginOptions && loginOptions.appState),
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    }
    loginWithRedirect(options)
  }, [error, isLoading, loginOptions, returnTo, routeIsAuthenticated])

  return routeIsAuthenticated
}
