import { Box, Container } from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import * as date from 'date-fns';
import { useState } from 'react';
import { RouteObject } from 'react-router-dom';
import useSWR from 'swr';
import { PageSpinner, Video } from '~/components';
import { ChartCard } from '~/components/ChartCard';
import { PageHeader } from '~/components/PageHeader';
import { useFarm } from '~/hooks/useFarm';
import { applyStandardDeviation } from '~/utils/stddev';

export const playRoute: RouteObject = {
  path: '/play',
  element: <Play />,
}

const defaultFrom = date.subWeeks(new Date(), 6)
const defaultTo = date.addDays(new Date(), 1)

function Play() {
  const { farm } = useFarm()

  const [from, setFrom] = useLocalStorage('settings.from', date.formatISO(defaultFrom, { representation: 'date' }))
  const [to, setTo] = useLocalStorage('settings.to', date.formatISO(defaultTo, { representation: 'date' }))
  const [params, setParams] = useState({ from, to })

  const { data: feederData = [], isLoading } = useSWR(
    `/farms/${farm?.id}/insights/play?dateFrom=${params.from}&dateTo=${params.to}`)

  const { data: activityMedia } = useSWR(
    `/farms/${farm?.id}/media/play-activity/${params.to.replace(/-/g, '')}`)

  const { data: heatmapMedia } = useSWR(
    `/farms/${farm?.id}/media/play-heatmap/${params.to.replace(/-/g, '')}`)

  const birdCount = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.birdCountAvg,
      min: d.birdCountMin,
      max: d.birdCountMax,
      stddev: applyStandardDeviation(d.birdCountAvg, d.birdCountStdDev),
    }
  })

  const clustering = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.clusteringAvg,
      min: d.clusteringMin,
      max: d.clusteringMax,
      stddev: applyStandardDeviation(d.clusteringAvg, d.clusteringStdDev),
    }
  })

  const lowActivity = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.lowActivityAvg,
      min: d.lowActivityMin,
      max: d.lowActivityMax,
      stddev: applyStandardDeviation(d.lowActivityAvg, d.lowActivityStdDev),
    }
  })

  const moderateActivity = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.moderateActivityAvg,
      min: d.moderateActivityMin,
      max: d.moderateActivityMax,
      stddev: applyStandardDeviation(d.moderateActivityAvg, d.moderateActivityStdDev),
    }
  })

  const highActivity = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.highActivityAvg,
      min: d.highActivityMin,
      max: d.highActivityMax,
      stddev: applyStandardDeviation(d.highActivityAvg, d.highActivityStdDev),
    }
  })

  const birdDensity = feederData.map((d: any) => {
    return {
      datetime: date.addHours(date.parseISO(d.date), d.hour),
      date: d.date,
      hour: d.hour,
      avg: d.birdDensityAvg,
      min: d.birdDensityMin,
      max: d.birdDensityMax,
      stddev: applyStandardDeviation(d.birdDensityAvg, d.birdDensityStdDev),
    }
  })

  const handleApply = () => {
    setParams({ from, to })
  }

  return (
    <Container maxWidth={false} sx={{ pb: '24px' }}>
      <PageHeader title="Play" from={from} to={to} setFrom={setFrom} setTo={setTo} handleApply={handleApply} />
      {isLoading && <PageSpinner />}
      {!isLoading && (
        <Box display={"grid"} gridTemplateColumns={"repeat(auto-fill, minmax(500px, 700px))"} gap={2}>
          <Video height='375px' width='100%' src={activityMedia.url} />
          <Video height='375px' width='100%' src={heatmapMedia.url} />
          <ChartCard title="Bird count" data={birdCount} />
          <ChartCard title="Clustering" data={clustering} />
          <ChartCard title="Low Activity" data={lowActivity} />
          <ChartCard title="Moderate Activity" data={moderateActivity} />
          <ChartCard title="High Activity" data={highActivity} />
          <ChartCard title="Bird Density" data={birdDensity} />
        </Box>
      )}

    </Container>
  )
}
