import { AppLayout, ErrorPage } from '~/modules/shell'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import { indexRoute, drinkerRoute, feederRoute, playRoute, wallRoute, settingsRoute } from '~/pages'

const routes: RouteObject = {
  path: '/',
  element: <AppLayout />,
  errorElement: <ErrorPage />,
  children: [indexRoute, drinkerRoute, feederRoute, playRoute, wallRoute, settingsRoute],
}

export default createBrowserRouter([routes])
