import * as React from 'react'
import {
  GetTokenSilentlyOptions,
  OAuthError,
  useAuth0,
} from '@auth0/auth0-react'

export function useAccessToken(): [
  string | undefined,
  (options?: GetTokenSilentlyOptions) => Promise<string | undefined>
] {
  const [accessToken, setAccessToken] = React.useState<string>()

  const { getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0()

  const getAccessToken = React.useCallback(
    async (options: GetTokenSilentlyOptions = {}) => {
      let token: string | undefined
      try {
        token = await getAccessTokenSilently(options)
      } catch (error: unknown) {
        if ((error as OAuthError).error !== 'consent_required') {
          throw error
        }

        token = await getAccessTokenWithPopup(options)
      }

      return token
    },
    []
  )

  React.useEffect(() => {
    getAccessToken().then(setAccessToken)

    return () => {
      setAccessToken(undefined)
    }
  }, [getAccessToken])

  return [accessToken, getAccessToken]
}
