import * as React from 'react'
import { useTranslation } from 'react-i18next'
import useForkRef from '~/hooks/useForkRef'

type VideoProps = React.HTMLProps<HTMLVideoElement>

const Video = React.forwardRef<HTMLVideoElement, VideoProps>(function Video(
  props,
  ref
) {
  const { ...videoProps } = props
  const { t } = useTranslation()
  const [hasWindow, setHasWindow] = React.useState(false)
  const videoRef = React.useRef<HTMLVideoElement | null>(null)
  const [error, setError] = React.useState(false)

  const handleRef = useForkRef(videoRef, ref)

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault()

      if (!videoRef.current) {
        return
      }

      if (event.code === 'Space') {
        if (videoRef.current?.paused) {
          videoRef.current?.play()
        } else {
          videoRef.current?.pause()
        }
      } else if (event.code === 'ArrowRight') {
        videoRef.current.currentTime += 1
      } else if (event.code === 'ArrowLeft') {
        videoRef.current.currentTime -= 1
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      {hasWindow && (
        <video
          controls
          {...videoProps}
          ref={handleRef}
          muted
          onError={() => {
            setError(true)
          }}
        >
          <p>
            Your browser doesn't support HTML video. Here is a
            <a href={videoProps.src}>link to the video</a> instead.
          </p>
        </video>
      )}
    </>
  )
})

export default Video