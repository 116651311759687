import type {} from '@mui/material/themeCssVarsAugmentation'
import { CSSObject, Theme, ThemeOptions } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
import { defaultTheme } from './defaultTheme'

interface ApplyDarkStyles {
  (scheme: CSSObject): CSSObject | undefined
}

declare module '@mui/material/styles' {
  interface Theme {
    applyDarkStyles: ApplyDarkStyles
  }

  interface ThemeOptions {
    applyDarkStyles?: ApplyDarkStyles
  }
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontFamilyCode?: string
    fontFamilySystem?: string
  }

  interface Typography {
    fontFamilyCode: string
    fontFamilySystem: string
  }
}

const systemFont = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
]

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  return {
    palette: {
      ...defaultTheme.palette,
      // TODO: Address issue with color variables making table borders black
      // mode,
    },
    typography: {
      fontFamily: [/* Add preferred font here */ ...systemFont].join(','),
      fontFamilyCode: [
        'Menlo',
        'Consolas',
        '"Droid Sans Mono"',
        'monospace',
      ].join(','),
      fontFamilySystem: systemFont.join(','),
      // Override typography variants below
      button: {
        textTransform: 'initial',
        fontWeight: 700,
        letterSpacing: 0,
      },
      allVariants: {
        scrollMarginTop: 'calc(var(--FlockFocus-header-height) + 32px)',
      },
    },
    shape: {
      borderRadius: 4,
    },
    applyDarkStyles(css: CSSObject) {
      if ((this as Theme).vars) {
        // CssVarsProvider is used
        const selector = (this as Theme)
          .getColorSchemeSelector('dark')
          .replace(/(\[[^\]]+\])/, ':where($1)')
        return {
          [selector]: css,
        }
      }

      if ((this as Theme).palette.mode === 'dark') {
        return css
      }

      return undefined
    },
  }
}
