import { SWRConfig } from 'swr';
import { useAccessToken } from '~/hooks';

const API = import.meta.env.VITE_API_URL || ''

export interface SWRContextProps {
  children?: React.ReactNode
}

function SWRContext({ children }: SWRContextProps) {
  const [_, getAccessToken] = useAccessToken()

  return (
    <SWRConfig
      value={{
        fetcher: async (url: string) => {
          const accessToken = await getAccessToken()
          url = `${API}${url}`
          return await fetch(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }).then((res) => res.json())
        },
      }}
    >
    {children}
    </SWRConfig>
  )
}

export default SWRContext