import { Stack, Button, Input } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import * as date from 'date-fns';

interface PageHeaderProps {
  title: string
  from: string;
  to: string;
  setFrom: (newValue: string) => void;
  setTo: (newValue: string) => void;
  handleApply: () => void;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, from, to, setFrom, setTo, handleApply }) => {
  return (
    <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
      <h2>{title}</h2>
      <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
        <DatePicker 
          onChange={(newValue: any) => setFrom(date.formatISO(newValue, { representation: 'date' }))}
          value={date.parseISO(from)}
          slotProps={{ textField: { size: 'small', variant: 'outlined' } }}
          label="From" />
        <span>-</span>
        <DatePicker
          onChange={(newValue: any) => setTo(date.formatISO(newValue, { representation: 'date' }))}
          value={date.parseISO(to)}
          slotProps={{ textField: { size: 'small', variant: 'outlined' } }}
          label="To" />
        <Button variant="outlined" onClick={handleApply}>
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};