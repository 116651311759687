import { createContext, useEffect, useState } from "react"
import useSWR from 'swr';

export interface FarmContextProps {
  children?: React.ReactNode
}

export const FarmContext = createContext<{farms: any[], farm: any, setFarm: (farm: any) => void}>({
  farms: [],
  farm: undefined,
  setFarm: () => {},
})

function FarmProvider({ children }: FarmContextProps) {
  const { data: farms, isLoading } = useSWR('/farms')

  const [farm, setFarm] = useState<any>()

  useEffect(() => {
    if (!isLoading && farms?.length > 0) {
      setFarm(farms[0])
    }
  }, [farms])

  return (
    <FarmContext.Provider value={{farms, farm, setFarm}}>
      {children}
    </FarmContext.Provider>
  )
}

export default FarmProvider
