import { createTheme } from '@mui/material/styles'

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#D6000D',
    },
    secondary: {
      main: '#2F4858',
    },
  },
})
