import { Card, CardContent, CardHeader } from '@mui/material';
import * as date from 'date-fns';
import { Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// min, max and stddev are not used in the chart
// but they are included in the data for future use
interface ChartCardProps {
  title: string
  data: [{
    datetime: Date,
    date: string,
    hour: number,
    avg: number,
    min: number,
    max: number,
    stddev: number
  }]
}

// To restore min, max and stddev in the chart, uncomment the lines below
export const ChartCard: React.FC<ChartCardProps> = ({ title, data }) => {
  const hasData = data.length > 0
  return (
    <Card variant='outlined' sx={{ height: '375px' }}>
      <CardHeader title={title} />
      <CardContent sx={{ height: '85%' }}>
        {!hasData && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            No data available
          </div>
        )}
        {hasData && (
          <ResponsiveContainer>
            <ComposedChart
              data={data}
              margin={{
                top: 5,
                right: 40,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
              <XAxis dataKey="datetime" tick={{ dy: 5 }} tickFormatter={(v, i) => date.format(v, 'yyyy-MM-dd HH:mm')} />
              <YAxis />
              <Tooltip labelFormatter={(l, p) => date.format(l, 'yyyy-MM-dd HH:mm')} />
              <Legend />
              <Line type="monotone" dataKey="avg" stroke='#00A1F2' dot={false} />
              {/* <Area type="monotone" dataKey="stddev" fillOpacity={0.8} fill="#8884d8" /> 
              <Line type="monotone" dataKey="min" strokeDasharray="3 3" stroke='#56423D' dot={false} />
              <Line type="monotone" dataKey="max" strokeDasharray="3 3" stroke='#BEA6A0' dot={false} /> */}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};