import AppHeader from './AppHeader'
import { Outlet } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'

function AppLayout() {
  return (
    <>
      <Stack>
        <AppHeader />
        <main>
          <Container maxWidth='xl'>
            <Outlet />
          </Container>
        </main>
      </Stack>
    </>
  )
}

export default AppLayout
