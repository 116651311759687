import { alpha, lighten, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { useAuth0 } from '@auth0/auth0-react'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import VideocamIcon from '@mui/icons-material/Videocam';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FarmPicker from './FarmPicker'
import { useFarm } from '~/hooks/useFarm'
import { Logout } from '@mui/icons-material'
import { Tooltip, Avatar, Menu, ListItemIcon, Divider, Typography, Button } from '@mui/material'
import React from 'react'

const Header = styled('header')(({ theme }) => {
  return [
    {
      position: 'sticky',
      top: 0,
      transition: theme.transitions.create('top'),
      zIndex: theme.zIndex.appBar,
      backdropFilter: 'blur(8px)',
      boxShadow: `inset 0px -1px 1px ${
        (theme.vars || theme).palette.grey[300]
      }`,
      backgroundColor: lighten(theme.palette.common.white, 0.8),
    },
    theme.applyDarkStyles({
      boxShadow: `inset 0px -1px 1px ${
        (theme.vars || theme).palette.primary.dark
      }`,
      backgroundColor: alpha(theme.palette.primary.dark, 0.7),
    }),
  ]
})

function AppHeader() {
  const { logout } = useAuth0()
  const { farms, farm, setFarm } = useFarm()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout()
  }

  return (
    <Header sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container maxWidth={false}>
        <Stack
          direction='row'
          sx={{
            alignItems: 'center',
            minHeight: 56,
          }}
        >
          <Box
            aria-label='Go to homepage'
            component={Link}
            sx={{
              lineHeight: 0,
              mr: 2,
            }}
            to='/'
          >
            <Box
              component="img"
              sx={{
                height: 48,
              }}
              alt="Queens Logo"
              src='/logo.png'
            />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button component={Link} to='/drinker'>
              Drinker
            </Button>
            <Button component={Link} to='/feeder'>
              Feeder
            </Button>
            <Button component={Link} to='/play'>
              Play
            </Button>
            <Button component={Link} to='/wall'>
              Wall
            </Button>
          </Box>
          <Box sx={{ ml: 'auto' }} />
          <FarmPicker options={farms} value={farm} onClick={(farm: any) => setFarm(farm)} />
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title={t('Account settings')}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem component={Link} to='/cameras'>
              <ListItemIcon>
                <VideocamIcon fontSize="small" />
              </ListItemIcon>
              {t('Cameras')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t('Logout')}
            </MenuItem>
          </Menu>
        </Stack>
      </Container>
    </Header>
  )
}

export default AppHeader
