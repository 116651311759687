import { Navigate, RouteObject } from 'react-router-dom'

export const indexRoute: RouteObject = {
  path: '/',
  element: <Home />,
}

function Home() {
  return (
    <Navigate to="/drinker" replace={true} />
  )
}
