import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import GlobalStyles from '@mui/material/GlobalStyles'
import theme from '~/modules/theme'

export interface ThemeContextProps {
  children?: React.ReactNode
}

function ThemeContext({ children }: ThemeContextProps) {
  return (
    <CssVarsProvider
      defaultMode='system'
      disableTransitionOnChange
      theme={theme}
    >
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--FlockFocus-header-height': '56px',
          },
        }}
      />
      {children}
    </CssVarsProvider>
  )
}

export default ThemeContext
