import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useRouteError } from 'react-router-dom'

function ErrorPage() {
  const error = useRouteError()

  console.error(error)

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Typography variant='h1'>Oops</Typography>
      <Typography variant='body1'>
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography variant='body1'>
        <i>{(error as any).statusText || (error as Error).message}</i>
      </Typography>
    </Stack>
  )
}

export default ErrorPage
